import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMClient from 'react-dom/client'

import {
  banquetSingleSpaReact as packagedBanquetSingleSpaReact,
  banquetSingleSpaReactOpts,
  initSentry,
  useSentry
} from '@toasttab/banquet-single-spa-react'
import * as Sentry from '@sentry/browser'
import { SetOptional } from 'type-fest'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { getSpaNameFromStackTrace } from './util/spa-name'

export * from '@toasttab/banquet-single-spa-react'

const DEV_ENVS = [ToastEnvironment.DEV, ToastEnvironment.PREPROD]
const isDevEnv = () => DEV_ENVS.includes(getCurrentEnvironment())

export type BanquetSingleSpaReact = ReturnType<
  typeof packagedBanquetSingleSpaReact
>

export const banquetSingleSpaReact: (
  options: SetOptional<banquetSingleSpaReactOpts, 'React'>
) => BanquetSingleSpaReact = (
  options: SetOptional<banquetSingleSpaReactOpts, 'React'>
) => {
  if (isDevEnv() && options?.sentry?.Sentry) {
    console.warn(
      'WARNING: Sentry is supplied by banquet-runtime-modules - ONLY supply your own version after consulting with the WEX team'
    )
  }

  const spaName = getSpaNameFromStackTrace()
  if (React.version.startsWith('18')) {
    if (isDevEnv() && (options.React || options.ReactDOM)) {
      console.group(
        `WARNING: React 18 issue detected in SPA ${spaName ?? 'unknown'}`
      )
      console.warn(
        'WARNING: As part of the React 18 upgrade, React and ReactDOM are supplied by banquet-runtime-modules - please run the remove-react-from-spa codemod.'
      )
      console.warn(
        'See the React 18 Playbook for more information: https://toasttab.atlassian.net/wiki/spaces/FE/pages/3254714594/React+18+Playbook#Upgrading-your-SPA.'
      )
      console.groupEnd()
    }
  } else {
    // If React 17 or below, single-spa-react requires the renderType to be set.
    options.renderType = 'render'
  }

  delete options.ReactDOM
  if (React.version.startsWith('18')) {
    options.ReactDOMClient = ReactDOMClient
  } else {
    options.ReactDOM = ReactDOM
  }

  return packagedBanquetSingleSpaReact({
    ...options,
    React,
    ...(options.sentry
      ? {
          sentry: {
            projectName: spaName,
            Sentry: Sentry,
            ...options.sentry
          }
        }
      : {})
  })
}

export { useSentry, initSentry }
